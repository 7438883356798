import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import Img from "gatsby-image";
import { BsArrowRight } from "react-icons/bs";

class PressRoomPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Press Room | Honest Paws®'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'At Honest Paws, we believe pets are family.'
      },
      children: []
    }

    this.theHonestVisionImg1 = this.props.data.theHonestVisionImg1.childImageSharp.fluid
    this.theHonestVisionImg2 = this.props.data.theHonestVisionImg2.childImageSharp.fluid
    this.theHonestVisionImg3 = this.props.data.theHonestVisionImg3.childImageSharp.fluid
    this.logoBlue = this.props.data.logoBlue.childImageSharp.fluid
    this.logoWhite = this.props.data.logoWhite.childImageSharp.fluid
    this.brandGuidelineImg = this.props.data.brandGuidelineImg.childImageSharp.fluid
    this.chelseaCeoImg = this.props.data.chelseaCeoImg.childImageSharp.fluid
    this.erikCeoImg = this.props.data.erikCeoImg.childImageSharp.fluid
    this.companyHistoryImg = this.props.data.companyHistoryImg.childImageSharp.fluid
    this.featuredInDesktopImg = this.props.data.featuredInDesktopImg.childImageSharp.fluid
    this.featuredInMobileImg = this.props.data.featuredInMobileImg.childImageSharp.fluid
    this.funFacts1Img = this.props.data.funFacts1Img.childImageSharp.fluid
    this.funFacts2Img = this.props.data.funFacts2Img.childImageSharp.fluid

  }

  render() {
    return (
      <Layout containerClassName={`page page-press-room`} location={this.props.location} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo }>
          <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
          { this.seo.children ? this.seo.children.map(el => el) : '' }
        </SEO>
        <div className="page-header-top-placeholder"></div>

        <section className="bg-white the-honest-vision mb-7">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-8 d-block offset-xl-2 text-left text-xl-center mb-5">
                <h2 className="text-primary mb-0">The Honest Vision</h2>
                <div className="description-text mt-2">
                  <p>At Honest Paws, we envision a world where dogs, cats, and
                  pets of all kind can live a happy, healthy life alongside their owners that is free of stress, joint
                    stiffness, and other common ailments.</p>
                  <p>We envision a world where cannabis is recognized for the healing agent it is; a world where we
                    use holistic medicine to treat the root cause and not the symptoms.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-yellow-light logos">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-4 text-left logos-img-container logos-img-container-first">
                <div className="">
                  <Img fluid={this.theHonestVisionImg1} alt="The Honest Paws Vision" />
                  <p className="font-weight-700">Leadership photos</p>
                  <a href="https://drive.google.com/drive/folders/1Rh4jNT9BiJviiDFPTeo3B1Jwoqzzpwz2?usp=sharing" target="_blank">View All <BsArrowRight title="Arrow Right" /></a>
                </div>
              </div>

              <div className="col-12 col-xl-4 text-left logos-img-container">
                <div className="">
                  <Img fluid={this.theHonestVisionImg2} alt="The Honest Paws Vision" />
                  <p className="font-weight-700">Our people and offices</p>
                  <a href="https://drive.google.com/drive/folders/1mBQy9PiWkZm6oLaOq1yN8feEgqwc5tSr?usp=sharing" target="_blank">View All <BsArrowRight title="Arrow Right" /></a>
                </div>
              </div>

              <div className="col-12 col-xl-4 text-left logos-img-container mb-6">
                <div className="">
                  <Img fluid={this.theHonestVisionImg3} alt="The Honest Paws Vision" />
                  <p className="font-weight-700">Videos</p>
                  <a href="https://drive.google.com/drive/folders/10Gt1pQKLRDeBdRGSaIBLv628O6DCjUml?usp=sharing" target="_blank">View All <BsArrowRight title="Arrow Right" /></a>
                </div>
              </div>

              <div className="col-12 d-xl-none mb-5">
                <div>
                  <Img fluid={this.logoBlue} alt="Honest Paws Logo" />
                </div>
                <div className="bg-primary">
                  <Img fluid={this.logoWhite} alt="Honest Paws Logo" />
                </div>
              </div>
              <div className="col-12 col-xl-7 text-left">
                <h2 className="text-primary mb-0">Logos</h2>
                <div className="description-text mt-2">
                  <p>The Honest Paws mark includes the Honest Paws name and logo, and any word, phrase, image, or 
                    other designation that identifies the source or origin of any of Honest Paws products. Please don’t 
                    modify the marks or use them in a confusing way, including suggesting sponsorship or endorsement 
                    by Honest Paws, or in a way that confuses Honest Paws with another brand (including your own). 
                    If you are unsure, please feel free to contact our pr representative,<a href="mailto:press@one.pet">press@one.pet</a>.</p>
                  <a href="https://drive.google.com/drive/folders/1g2MUz8qKkL4uQSDgi8YvmOW0-j2ZJu8A?usp=sharing" target="_blank">View All<BsArrowRight title="Arrow Right" /></a>
                </div>
              </div>
              <div className="col-xl-4 offset-xl-1 d-none d-xl-block mt-6">
                <div>
                  <Img fluid={this.logoBlue} alt="Honest Paws Logo" />
                </div>
                <div className="bg-primary">
                  <Img fluid={this.logoWhite} alt="Honest Paws Logo" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light brand-guideline">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 offset-xl-1 col-12">
                <Img fluid={this.brandGuidelineImg} alt="Brand Guideline" />
              </div>
              <div className="col-12 col-xl-5 text-left">
                <h2 className="text-primary mb-0 mt-5">Brand Guideline</h2>
                <div className="description-text mt-2">
                  <a target="_blank" href="https://drive.google.com/file/d/1G05Xew-kVbQlWQXptqpA4WUcRN0Xf4-7/view?usp=sharing">Download <BsArrowRight title="Arrow Right" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white the-honest-vision">
          <div className="container">
            <div className="row">
              <div className="col-12 text-left text-xl-center mb-5">
                <h2 className="text-primary mb-0">About Our Co-Founders</h2>
              </div>

              <div className="col-12 col-xl-5 offset-xl-1 mb-4">
                <Img fluid={this.chelseaCeoImg} alt="Chelsea CEO" />
              </div>
              <div className="col-12 col-xl-5 text-left">
                <p className="font-weight-bold">Chelsea Rivera</p>
                <p>
                  Chelsea Rivera graduated from the University of Texas at Austin with a degree in
                  Radio-Television-Film & and Advertising. Upon graduation, she moved to Los Angeles for an internship
                  in digital content at Maker Studios. Having been a pet lover all her life, Chelsea combined her love
                  of digital content and pets to co-found Honest Paws with her brother Erik. As Head of Content,
                  Chelsea loves educating pet parents on holistic wellness. She currently lives with her dog, Baby
                  Rose, in Atlanta.
                </p>
              </div>
              <hr className="col-12 my-6 d-none d-xl-block"/>
              <div className="col-12 d-xl-none mt-5 mb-4">
                <Img fluid={this.erikCeoImg} alt="Erik CEO" />
              </div>
              <div className="col-12 col-xl-5 offset-xl-1 text-left">
                <p className="font-weight-bold">Erik Rivera</p>
                <p>
                  Erik Rivera graduated from the United States Naval Academy and became an Explosive Ordnance Disposal
                  Officer. After years of serving in the Navy, Erik embraced his entrepreneurial spirit and started
                  his first internet business. After hearing about the success that his sister had with CBD for her
                  dog, Erik co-founded Honest Paws and now serves as the CEO.
                </p>
              </div>
              <div className="col-xl-5 d-none d-xl-block">
                <Img fluid={this.erikCeoImg} alt="Erik CEO" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-yellow-light our-mission">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 mx-auto text-left text-xl-center my-5">
                <h2 className="text-primary mb-3">Our Mission</h2>
                <p>At Honest Paws, we believe pets are family. Our mission is to empower every pet owner to take
                  charge of their pet’s health in a safe, effective, and holistic way.</p>
                <p>We do this by offering premium CBD products for pets that promote relaxation, support healthy
                  bones and joints, and aid sensitive skin. We want pet parents and their four-legged kids to live a
                  happy and healthy life together.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light our-company-history">
          <div className="container">
            <div className="row">
              <div className="col-12 text-left text-xl-center my-5">
                <h2 className="text-primary mb-3">Our Company History</h2>
              </div>
              <div className="col-12 col-xl-5 offset-xl-1 mb-4">
                <Img fluid={this.companyHistoryImg} alt="Company History" />
              </div>
              <div className="col-12 col-xl-5 text-left">
                <p>When Chelsea’s dog, Baby Rose, was just a young pup, she started suffering from gut-wrenching
                  seizures. Chelsea, determined to find a solution, searched for different options to help her best friend.
                </p>
                <p>She noticed that anti-seizure medication often had very negative side effects that could likely
                  cause other problems down the line. Not to mention, these medications were not guaranteed to work.
                  Because of this, she dove into the world of holistic medicine. Her research led her to CBD. After a
                  month of consistent use, Baby Rose’s seizures had completely stopped… and this is where it all began!
                </p>
                <p>Chelsea believed there was an opportunity to empower other dog moms and pets parents to heal their
                  best friends. This was the genesis of Honest Paws.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white featured-in">
          <div className="container">
            <div className="row">
              <div className="col-12 text-left text-xl-center mt-5">
                <h2 className="text-primary mb-3">Featured In</h2>
              </div>
              <div className="col-12">
                <Img fluid={this.featuredInDesktopImg} alt="Featured In" className="d-none d-xl-block" />
                <Img fluid={this.featuredInMobileImg} alt="Featured In"  className="d-xl-none"/>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-yellow-light get-in-touch">
          <div className="container">
            <div className="row">
              <div className="col-12 text-left text-xl-center mt-5">
                <h2 className="text-primary mb-5">Get In Touch</h2>
              </div>
              <div className="col-12 col-xl-4 mx-auto text-left text-xl-center mb-5">
                <div className="person-name">Media Relations</div>
                <p>press@one.pet</p>
                <a href="mailto:press@one.pet">Send Email <BsArrowRight title="Arrow Right" /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light diversity">
          <div className="container">
            <div className="row">
              <div className="col-12 text-left text-xl-center my-5">
                <h2 className="text-primary mb-3">Diversity</h2>
              </div>
              <div className="col-12 col-xl-10 offset-xl-1 text-left text-xl-center">
                <p>The Honest Paws team is composed of a diverse group of employees from a wide range of backgrounds.
                  Team members represent different cultures, speak different languages, and live in different places.
                  With that said, we have built an inclusive team that encourages everyone to express their point of
                  view and bring different ideas to the table.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white fun-facts">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-5 offset-xl-1">
                <h2 className="text-primary mb-3">Fun Facts</h2>
                <ul className="fun-facts-ul pl-3">
                  <li className="mb-3">56 employees as of 2020, and growing fast.</li>
                  <li className="mb-3">Located across the globe in places like San Juan,PR; San Diego, CA; and Montevideo, Uruguay.</li>
                  <li className="mb-3">Sold in 526 retail locations.</li>
                  <li className="mb-3">In addition to an English blog, Honest Paws publishes content in Spanish & Portuguese as well.</li>
                </ul>
              </div>
              <div className="col-12 col-xl-5 text-left">
                <Img fluid={this.funFacts1Img} alt="Fun Facts 1" />
                <Img fluid={this.funFacts2Img} alt="Fun Facts 2" />
              </div>
            </div>
          </div>
        </section>


      </Layout>
    )
  }
}

export default PressRoomPage

export const PressRoomPagePageQuery = graphql`
  query PressRoomPagePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    theHonestVisionImg1 : file(relativePath: { eq: "landing-pages/thehonestvision-img1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    theHonestVisionImg2 : file(relativePath: { eq: "landing-pages/thehonestvision-img2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    theHonestVisionImg3 : file(relativePath: { eq: "landing-pages/thehonestvision-img3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    logoBlue : file(relativePath: { eq: "landing-pages/logo-blue-v1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoWhite : file(relativePath: { eq: "landing-pages/logo-white-v1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandGuidelineImg : file(relativePath: { eq: "landing-pages/brand-guideline-img1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    erikCeoImg : file(relativePath: { eq: "landing-pages/erik-ceo-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chelseaCeoImg : file(relativePath: { eq: "landing-pages/chelsea-ceo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    companyHistoryImg : file(relativePath: { eq: "landing-pages/company-history-chelsea.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuredInDesktopImg : file(relativePath: { eq: "landing-pages/featured-in-desktop.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuredInMobileImg : file(relativePath: { eq: "landing-pages/featured-in-mobile.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    funFacts1Img : file(relativePath: { eq: "landing-pages/fun-facts-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    funFacts2Img : file(relativePath: { eq: "landing-pages/fun-facts-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`